@include font-face('Bitter',
  '../fonts/Bitter/Bitter-Regular',
  normal, // font weight
  normal, // font style
  ttf);

@include font-face('Bitter',
  '../fonts/Bitter/Bitter-Bold',
  bold, // font weight
  normal, // font style
  ttf);