html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: $orange;
  transition: $trs;
  &:hover {
    color: $dark;
  }
}

a[href],
button {
  cursor: pointer;
  user-select: none;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  list-style: none;
}

button {
  &:not(:disabled) {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

b, strong {
  font-weight: bold;
}

body {
  display: block;
  position: relative;
  font-family: $ff-text;
  background: #fff;
  min-width: 320px;
  font-size: 1.6rem;
  line-height: 1.63;
  font-weight: 400;
  color: rgba(0, 33, 41, 0.85);
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include md {
    font-size: 1.8rem;
    line-height: 1.56;
  }

}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
textarea {
  box-shadow: none;
  font: normal 400 18px/1.78 $ff-text;
  color: #002129;
  padding: 11px 21px;
  border: solid 1px #002129;
  background-color: #fff;
  width: 440px;
  max-width: 100%;
}

::placeholder {
  font-style: italic;
  color: #002129;
}

:-ms-input-placeholder {
  font-style: italic;
  color: #002129;
}

input[type='submit'] {
  cursor: pointer;
}