.preloader {
  display: none;
}

.js .preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999999;

  perspective: 2500px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 30px;
  text-transform: uppercase;

  span {
    font-size: 10vw;
    color: $red;
    line-height: 1;
    font-weight: 700;
  }

  span:first-child {
    animation: preloader-flips 1.5s linear infinite;
    transform-origin: center 0.345em;
    display: block;
  }
}

@keyframes preloader-flips {
  0% {
    transform: rotateX(0) scale(1);
  }

  25% {
    transform: rotateX(-90deg) scale(1);
  }

  26% {
    transform: rotateX(-90deg) scale(-1);
  }

  50% {
    transform: rotateX(0) scale(-1);
  }

  75% {
    transform: rotateX(-90deg) scale(-1);
  }

  76% {
    transform: rotateX(-90deg) scale(1);
  }

  100% {
    transform: rotateX(0) scale(1);
  }
}
