.tac {
  text-align: center !important;
}

.tal {
  text-align: left !important;
}

.tar {
  text-align: right !important;
}

.df {
  display: flex;
}

.text--red {
  color: $red;
}

.text--green {
  color: $green;
}

.text--orange {
  color: $orange;
}

.text--peach {
  color: $peach;
}

.text--teal {
  color: $teal
}

.font--l, .has-medium-font-size {
  font-size: 20px;
  font-weight: 600;
  @include md-max {
    line-height: 1.5;
  }
  @include md {
    font-size: 22px;
  }
}
