.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  font: 700 14px/1.57 $ff-accent;
  letter-spacing: 1.17px;
  width: 100%;

    &__inner {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;

      width: 100%;
      transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1), background-color .5s ease;

    }

    &.scrolled &__inner {

      transform: translateY(-100%);

    }

    &.scrolled--visible &__inner {
      border-bottom: solid 1px $orange;
      background-color: rgba(255, 255, 255, 0.9);
      transform: translateY(0);
    }

}

.stripe {
  background-color: rgba(255, 255, 255, 0.74);
  transition: 0.2s;

  &.opened {
    background-color: rgba(255,255,255,.95);
  }

  &-opened {
    height: 14rem;
    background-color: rgba(255,255,255,.95);
  }


  .header-top {
    display: flex;

    &__left {
      display: flex;
      justify-content: flex-start;
    }

    &__right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-left: 38px;
      flex-grow: 1;

      @include md {
        justify-content: flex-end;
      }

      .search-button {
        display: flex;
        align-items: center;
        cursor: pointer;

        @include md {
          margin-left: 16.5%;
        }

        @include md-max {
          width: 24px;
        }

        img {
          width: 100%;
        }
      }

      a {
        text-align: right;
        color: #002129;

        @include md-max {
          padding-right: 3px;
        }

        @include md {
          padding-left: 8px;
        }
      }
    }
  }
}

.search {
  transform: translateY(-15px);
  overflow: hidden;

  &-opened {
    overflow: auto;
    transition: 0.3s;
    transform: translateY(15px);
  }

  &-wrapper {
    height: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &-opened {
      height: 100%;
      padding-bottom: 3rem;
    }
  }

  &-input {
    width: 45rem;
    padding: 11px 22px;
    border: solid 1px #0094a6;
    background-color: rgba(255, 255, 255, 0.85);
    font-family: 'Open Sans';
    font-size: 18px;
    font-style: italic;
    line-height: 1.78;
    color: #002129;
    position: relative;

    &::placeholder {
      color: #002129;
    }
  }

  &-submit {
    background-color: transparent;
    background-image: url(../img/header/search.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 24px;
    height: 24px;
    position: absolute;
    cursor: pointer;
    right: 2rem;
    top: 1.5rem;
  }
}

.links-menu {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  margin: 0 -20px;
  & > li {
    position: relative;
    padding: 8px 15px;
    margin: 0 20px;
    @include md-max {
      padding: 13px 15px;
    }
    &.menu-item-has-children {
      background:  url("../img/header/arrow.svg") right center /auto no-repeat;
      padding-right: 15px;
      &:hover {
        .sub-menu {
          max-height: 500px;
          opacity: 1;
          overflow: initial;
          padding: 32px;
          border: solid 1px #f26645;
          z-index: 100;
        }
      }
      .sub-menu {
        position: absolute;
        left: 15px;
        white-space: nowrap;
        top: calc(100% + 15px);
        background-color: rgba(255,255,255,.95);
        padding: 0 32px;
        font-size: 16px;
        font-weight: 400;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: $trs;
        &:before {
          content: '';
          position: absolute;
          top: -15px;
          height: 15px;
          left: 0;
          width: 100%;
        }
        &:after {
          position: absolute;
          content: "";
          width: 15px;
          height: 15px;
          border-left: solid 1px #f26645;
          border-top: solid 1px #f26645;
          background-color: rgba(255,255,255,.95);
          top: -8px;
          left: 15%;
          transform: rotate(45deg);
        }
        li {
          padding: 5px 0;
        }
        a:hover {
          color: $orange;
        }
      }
    }
  }
}


.temperature {
  display: flex;
  align-items: center;

  img {
    margin-right: 0.8rem;
    width: 1rem;
  }

  a {
    font-size: 1.8rem;
    line-height: 1.22;
    letter-spacing: 1.5px;
    color: #004a59;
  }
}

.wrapper {

  .logo {
    display: block;
    width: 95px;
    @include md {
      width: 131px;
    }
    img {
      width: 100%;
    }
  }
  .menu-mobile {
    display: none;

    nav {
      z-index: 5;
    }
  }

  .left {
    display: flex;
    align-items: center;
    @include md-max {
      width: 100%;
    }

    .guide-button {
      display: none;
    }
  }

  .center {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;

    .links-list {
      margin-right: 2rem;
      margin-left: 2rem;
      display: flex;
      align-items: center;
      border-bottom: 1px solid transparent;
      transition: 0.3s;
      position: relative;

      a {
        margin-right: 0.7rem;
        text-align: right;
        color: #002129;
        white-space: nowrap;
      }

      .main-nav {
        position: absolute;
        top: 5rem;
        border: solid 1px #f26645;
        background-color: rgba(255, 255, 255, 0.95);
        height: inherit;
        width: inherit;
        padding: 31px 36px;
        overflow: hidden;
        height: 0;

        &::after {
          position: absolute;
          content: '';
          width: 15px;
          height: 15px;
          border-left: solid 1px #f26645;
          border-top: solid 1px #f26645;
          background-color: rgba(255, 255, 255, 0.95);
          top: -8px;
          left: 3px;
          transform: rotate(45deg);
        }

        .menu {
          .menu-item {
            padding: 0.6rem 0;
            border-bottom: solid 1px transparent;
            transition: 0.3s;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 20rem;

            a {
              font-family: Bitter;
              font-size: 16px;
              line-height: 1.38;
              color: #002129;
            }

            img {
              display: none;
              transition: 0.3s;
            }


          }

          .sub-menu {
            .menu-item {
              a {
                font-family: 'Open Sans';
                font-size: 16px;
                line-height: 1.38;
                color: rgba(0, 33, 41, 0.85);

              }
            }
          }

          .menu-item:hover {
            border-bottom: solid 1px #f5a378;

            a {
              color: #f26645;
            }

            img {
              display: inline;
            }
          }
        }


        h5 {
          font-family: Bitter;
          font-size: 16px;
          font-weight: bold;
          line-height: 1.38;
          letter-spacing: 1.33px;
          text-align: left;
          color: #002129;
          margin-bottom: 1rem;
        }
      }

    }

    .links-list:hover {
      border-bottom: 1px solid #c73617;
    }

  }


  .right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;


    .guide-button {
      font: 700 14px $ff-accent;
      border: solid 1px #f26645;
      background-color: rgba(255, 255, 255, 0.85);
      transition: 0.3s;

      a {
        color: #002129;
      }
    }

    .guide-button:hover {
      background-color: #f26645;
    }
  }

}

.header-middle {
  padding: 20px 0;

  @include md {
    padding: 43px 0 30px;
  }


  .wrapper {
    display: flex;
    align-items: center;
    @include md {
      justify-content: center;
    }
  }
}

.button {
  border: solid 1px #fff;
  padding: 1.3rem 2.4rem;
  border-radius: 27px;
}


@media (max-width: 767px) {

  header {

    .stripe {
      .header-top__left {
        width: auto;
      }
    }

    .search-button {
      a {
        display: none;
      }
    }


  }

  .main-nav {
    overflow: hidden;
    height: 0;
  }

  .opened {
    overflow: visible;
    height: auto;
  }

  .links-list {

    a {
      white-space: nowrap;
    }
  }

  .wrapper {
    .menu-mobile {
      display: flex;
      margin-right: 2rem;

      .menu-btn {
        display: block;
        width: 32px;
        height: 40px;
        position: relative;
        margin: 0;
        padding: 0 1rem;
      }

      .menu-btn span {
        margin: 0;
        padding: 0;
        position: absolute;
        top: 50%;
        margin-top: -1px;
        left: 30%;
        margin-left: -10px;
        width: 20px;
        height: 2.5px;
        background-color: #f26645;
        border-radius: 20%;
      }

      .menu-btn span::before,
      .menu-btn span::after {
        margin: 0;
        padding: 0;
        position: absolute;
        top: 50%;
        margin-top: -1px;
        left: 50%;
        margin-left: -10px;
        width: 32px;
        height: 2.5px;
        background-color: #f26645;
        border-radius: 20%;
      }

      .menu-btn span::before,
      .menu-btn span::after {
        content: "";
        display: block;
        transition: 0.3s;
      }

      .menu-btn span::before {
        transform: translateY(-12px);
      }

      .menu-btn span::after {
        transform: translateY(12px);
      }

      .menu-btn_active span {
        height: 0;
      }

      .menu-btn_active span::before {
        transform: rotate(45deg);
      }

      .menu-btn_active span::after {
        transform: rotate(-45deg);
      }

      nav {
        margin-top: 7rem;
        position: absolute;
        background-color: transparent;
        width: 91%;
      }
    }

    .left {
      width: 50%;

      .guide-button {
        margin-left: 4rem;
      }
    }

    .center {
      display: none;
    }

    .menu {
      padding: 3.2rem;
      border: solid 1px #f26645;
      background-color: rgba(255, 255, 255, 0.95);
      width: 100%;

      &::after {
        position: absolute;
        content: '';
        width: 15px;
        height: 15px;
        border-left: solid 1px #f26645;
        border-top: solid 1px #f26645;
        background-color: rgba(255, 255, 255, 0.95);
        top: -8px;
        left: 3px;
        transform: rotate(45deg);
      }
    }

    .menu-item {
      margin: 0.8rem 0;
      width: 100%;
    }

    .menu-item-has-children {
      a {
        font-family: Bitter;
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1.38;
        letter-spacing: 1.33px;
        text-align: right;
        color: #002129;
        text-transform: uppercase;
      }

      .sub-menu {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;

        a {
          font-family: Bitter;
          font-size: 1.6rem;
          font-weight: normal;
          line-height: 1.38;
          color: #002129;
          text-transform: none;
        }
      }
    }
  }
}

.opened {
  height: auto;
  overflow: visible;
}

