.fade-up-slowly {
  opacity: 0;
  transform: translateY(15px);
  animation: remove-transform-and-opacity 0.8s
    cubic-bezier(0.77, 0, 0.175, 0.75) forwards;
}

.fade-down-slowly {
  opacity: 0;
  transform: translateY(-15px);
  animation: remove-transform-and-opacity 0.8s
    cubic-bezier(0.77, 0, 0.175, 0.75) forwards;
}

.slider-effect {
  .slick-slide {
    opacity: 1 !important;

    &.visible {
      animation: slider 1.7s cubic-bezier(0.75, 0.25, 0.25, 0.75);
      z-index: 2000 !important;
      overflow: hidden;
    }
  }
}

@keyframes remove-transform-and-opacity {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slider {
  100% {
    transform: translateX(-110%);
  }
}

.main-nav__list a,
.link-hover-animation {
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  user-select: none;

  letter-spacing: 0.1em;
  font-weight: 400;
  text-transform: uppercase;
  color: $dark;
  position: relative;

  @supports (-webkit-text-fill-color: transparent) and
    (background-clip: text) {
    -webkit-text-fill-color: transparent;
    background-clip: text;
    background-image: linear-gradient(to top, $red, $red 50%, $dark 50%);
    background-size: 100% 200%;
    background-position: 0 0;
  }

  @include md-max {
    font-size: 24px;
    display: inline-block;
  }

  @include md {
    display: block;
    font-size: 16px;
  }
}

.link-hover-animation:hover,
.main-nav__list li:not(.current-menu-item) a[href]:hover {
  color: $red;

  @supports (-webkit-text-fill-color: transparent) and
    (background-clip: text) {
    background-position: 0 100%;
  }
}