.socials {
  display: flex;
  li {
    margin: 0 8px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 56px;
      height: 56px;
      &:hover {
        svg {
        }
      }
    }
    svg {
      width: 26px;
      height: 26px;
      fill: $dark;
      transition: $trs;
    }
  }
}