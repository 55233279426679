$ff-text: 'Open Sans', sans-serif;
$ff-accent: 'Bitter', serif;

$trs: all 0.2s ease-in;
$cubic: cubic-bezier(0.77, 0, 0.175, 0.75);
$trs-line: height 1.5s $cubic 1.5s;

// colors
$accent: #333;
$dark: #002129;
$red: #c73617;
$pale: #fde2d5;
$blue: #D9F1FD;
$orange: #f26645;
$green: #0094a6;
$peach: #f5a378;
$teal: #004a59;

