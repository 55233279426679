.footer {
  background-image: url(../img/footer/footer-bottom-bg.webp);
  background-repeat: no-repeat;
  margin-top: -20rem;
  background-size: 100vw;

  @include md {
    padding-bottom: 5rem;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16rem;

    .logos {
      display: flex;
      align-items: center;

      .kalispell-logo {
        padding-right: 5rem;
        border-right: solid 1px #fff;
      }


      .links {
        display: flex;
        align-items: center;

        a {
          margin-left: 5rem;
        }
      }
    }

    .about {
      display: flex;
      font: 700 14px/1.57 $ff-accent;
      letter-spacing: 1.17px;

      a {
        text-align: right;
        color: #fff;
        margin-left: 3.6rem;
        transition: 0.3s;
        border-bottom: solid 1px transparent;

      }

      a:hover {
        color: $orange;
      }
    }
  }

  &__bottom {
    padding-bottom: 12.5rem;
    padding-top: 5.7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .copyright {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        color: #fff;
        padding-right: 2rem;
      }

      ul {
        display: flex;

        a {
          padding-left: 2rem;
          padding-right: 2rem;
          border-left: solid 1px #fff;
          color: #fff;

          &:hover {
            color: $orange;
          }
        }
      }

    }
  }
}



@media (max-width: 767px) {
  .footer {
    background-image: url(../img/footer/footer-bottom-bg-mobile.webp);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .footer__bottom {
    flex-direction: column-reverse;
    padding-bottom: 3.5rem;
    padding-top: 3.2rem;

    .copyright {
      flex-direction: column;
      margin-top: 3.2rem;
      @include md-max {
        margin-top: 5.2rem;
      }

      li {
        a {
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }

      li:first-child {
        a {
          border-left: none;
        }
      }

      p {
        padding-right: 0;
      }
    }
  }

  .footer__top {
    flex-direction: column;
    padding-top: 9rem;

    .logos {
      flex-direction: column;
      width: 100%;

      .kalispell-logo {
        padding-right: 0;
        border-right: none;
        position: relative;
        padding-bottom: 3.3rem;
        max-width: 200px;
      }

      .kalispell-logo:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 40%;
        width: 80%;
        height: 1px;
        margin-left: -30%;
        background-color: white;
      }

      .links {
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin-top: 3.2rem;

        a {
          margin-left: 0;
        }
      }
    }

    .about {
      justify-content: space-around;
      width: 100%;
      margin-top: 5rem;
      @include md-max {
        margin-top: 3rem;
        margin-bottom: 2rem;
      }

      a {
        margin-left: 0;
      }
    }
  }
}


@media (min-width: 768px) and (max-width: 991px) {
  .footer {
    background-size: cover;

    &__top {
      flex-direction: column;

      .about {
        padding-top: 5rem;

        a {
          margin-left: 1.8rem;
          margin-right: 1.8rem;
        }
      }
    }

    &__bottom {
      flex-direction: column-reverse;
      padding-bottom: 8rem;

      .socials {
        padding-bottom: 3rem;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1365px) {

  .footer {
    margin-top: -15rem;

    .container {
      padding: 0 30px;
    }

    &__top {
      padding-top: 10rem;
    }

    &__bottom {
      padding-bottom: 5rem;
      padding-top: 5rem;
    }
  }
}

@media (min-width: 1366px) and (max-width: 1679px) {
  .footer__bottom {
    padding-bottom: 6rem;
  }
}

@media (min-width: 1680px) {
  .footer {
    margin-top: -30rem;
  }
}
