h1 {
  font: 700 6.4rem/0.97 $ff-text;
  @include md {
    font-size: 8.4rem;
    line-height: 0.98;
  }
}

h2 {
  font: 700 3.8rem/1 $ff-text;
  margin: 0 0 24px;
  @include md {
    font-size: 4.8rem;
  }
}

h3 {
  font: 400 2.4rem/1.25 $ff-accent;
  letter-spacing: 4px;
  margin: 0 0 24px;
  text-transform: uppercase;
  @include md {
    font-size: 2.8rem;
    line-height: 1.14;
  }
}

h4 {
  font: 700 2.4rem $ff-text;
  margin: 0 0 24px;
  @include md {
    font-size: 2.8rem;
  }
}

h5 {
  font: 600 2.2rem/1.6 $ff-text;
  margin: 0 0 24px;
}


h6 {
  font: 700 14px/1.29 $ff-accent;
  letter-spacing: 1.17px;
  text-transform: uppercase;
  margin: 0 0 24px;
}

.accent-title {
  -webkit-text-stroke: 1px rgba(0, 74, 89, 0.33);
  font: 700 6rem/0.83 $ff-accent;
  color: #fff;
  text-align: left;
  transition: all 0.5s cubic-bezier(.455,.03,.515,.955);
  transform-origin: center;
  opacity: 1;
  margin-bottom: 3rem;
  @include lg {
    font-size: 15rem;
    line-height: 0.91;
    margin-bottom: 8rem;
  }
  &:not(.aos-animate) {
    transform: scale(1.3);
    opacity: 0;
    -webkit-text-stroke: 1px #000;
  }
}

p:not(:last-child) {
  margin-bottom: 40px;
}